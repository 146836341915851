/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 *
 * OpenCRVS is also distributed under the terms of the Civil Registration
 * & Healthcare Disclaimer located at http://opencrvs.org/license.
 *
 * Copyright (C) The OpenCRVS Authors located at https://github.com/opencrvs/opencrvs-core/blob/master/AUTHORS.
 */
import React from 'react'
import { usePermissions } from '@client/hooks/useAuthorization'
import { Scope } from '@opencrvs/commons/client'

interface ScopeComponentProps {
  scopes?: Scope[]
  denyScopes?: Scope[]
  children: React.ReactNode
}

const ProtectedComponent: React.FC<ScopeComponentProps> = ({
  scopes,
  denyScopes,
  children
}) => {
  const { hasAnyScope } = usePermissions()
  const hasRequiredScope = !scopes || hasAnyScope(scopes)
  const hasDeniedScope = denyScopes && hasAnyScope(denyScopes)

  return hasRequiredScope && !hasDeniedScope ? <>{children}</> : null
}

export default ProtectedComponent
